import React, { useState, useEffect }  from 'react';
import FormBase from '../components/FormBase';
import Markdown from 'react-markdown';
import './documentation.css';

export default function Documentation(prop) {
  const doc_path = 'doc/document.md';
  const [post, setPost] = useState('');

  useEffect(() => {
    fetch(doc_path)
    .then(response => response.text())
    .then(text => setPost(text))
    .catch(err => console.log(err));
  },[]);

  return (
    <FormBase title="User Guide">
      <div className='documentContent'>
        <Markdown>
          {post}
        </Markdown>
      </div>
    </FormBase>
  )
}