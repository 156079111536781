import React from 'react';
import './FormBase.css';

export default function FormBase(prop) {
  return (
    <div className={'mainContainer formBackground'}>
      <div className={'form'} >
        <div className={'title'}>
          {prop.title}
        </div>
        <div className={'ball1'} >
          <div className={'content'} >
            <img src={'/img/form_ball1.svg'} alt={'ball 1'} width={'100%'} height={'100%'} />
          </div>
        </div>
        <div className={'ball2'} >
          <div className={'content'} >
            <img src={'/img/form_ball2.svg'} alt={'ball 2'} width={'100%'} height={'100%'} />
          </div>
        </div>
        <div className={'ball3'} >
          <div className={'content'} >
            <img src={'/img/form_ball3.svg'} alt={'ball 3'} width={'100%'} height={'100%'} />
          </div>
        </div>
        <div className={'ball4'} >
          <div className={'content'} >
            <img src={'/img/form_ball2.svg'} alt={'ball 4'} width={'100%'} height={'100%'} />
          </div>
        </div>
        <div className={'dialog'} />
        {prop.children}
      </div>
    </div>
  )
}